"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  data: function data() {
    return {
      pnoneiconlianluo9show: false
    };
  },
  created: function created() {
    var _this = this;
    this.bus.$on('onphoneisshow', function (val) {
      _this.pnoneiconlianluo9show = val;
    });
  },
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapMutations)('globalpop', ['setvisible7', 'setcalltype7'])), {}, {
    seal_README: function seal_README() {
      this.setvisible7(false);
      this.setcalltype7(true);
    },
    reference7: function reference7() {
      this.setvisible7(!this.$store.state.globalpop.visible7);
    }
  })
};