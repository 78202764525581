"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCampaignData = addCampaignData;
exports.addCaseAdjustRecord = addCaseAdjustRecord;
exports.addCaseEnd = addCaseEnd;
exports.addCaseFrozen = addCaseFrozen;
exports.addCaseLeave = addCaseLeave;
exports.addCaseReduction = addCaseReduction;
exports.addCaseRepay = addCaseRepay;
exports.addCaseUserLabel = addCaseUserLabel;
exports.addLawsuit = addLawsuit;
exports.addNotification = addNotification;
exports.addUseContact = addUseContact;
exports.addobtainblacklistlist = addobtainblacklistlist;
exports.adjustRecordExport = adjustRecordExport;
exports.appletsAddCaseRepay = appletsAddCaseRepay;
exports.applyGs = applyGs;
exports.backCase = backCase;
exports.batchCaseDistribution = batchCaseDistribution;
exports.batchFrozenCase = batchFrozenCase;
exports.caseBorrowExport = caseBorrowExport;
exports.caseCustomerAttachmentExport = caseCustomerAttachmentExport;
exports.caseEmployeeList = caseEmployeeList;
exports.caseInfoCaseDistribution = caseInfoCaseDistribution;
exports.caseInfoChannel = caseInfoChannel;
exports.caseInfoSmartDistribution = caseInfoSmartDistribution;
exports.caseInfoclearAdjusUrl = caseInfoclearAdjusUrl;
exports.caseLeaveExport = caseLeaveExport;
exports.caseManagerExport = caseManagerExport;
exports.caseManagerList = caseManagerList;
exports.channelExport = channelExport;
exports.channelFronzenChannel = channelFronzenChannel;
exports.channelList = channelList;
exports.channelSaveChannel = channelSaveChannel;
exports.channelUpdateChannel = channelUpdateChannel;
exports.companyAccount = companyAccount;
exports.createTctjgzh = createTctjgzh;
exports.delLawsuit = delLawsuit;
exports.deleteTag = deleteTag;
exports.deleteUseContact = deleteUseContact;
exports.doBatchCaseRepay = doBatchCaseRepay;
exports.doCaseReduction = doCaseReduction;
exports.doCaseReductionExport = doCaseReductionExport;
exports.doCaseRepay = doCaseRepay;
exports.doDistributionExport = doDistributionExport;
exports.editUseContact = editUseContact;
exports.entrustHistoryList = entrustHistoryList;
exports.exportCase = exportCase;
exports.frozenOrUnfrozenCase = frozenOrUnfrozenCase;
exports.getBorrowInfoByCaseId = getBorrowInfoByCaseId;
exports.getCallRecordByCallUuid = getCallRecordByCallUuid;
exports.getCaseAdjustRecordByCaseId = getCaseAdjustRecordByCaseId;
exports.getCaseAdjustRecordList = getCaseAdjustRecordList;
exports.getCaseAttachmentList = getCaseAttachmentList;
exports.getCaseAttachmentListByCaseId = getCaseAttachmentListByCaseId;
exports.getCaseBankInfoList = getCaseBankInfoList;
exports.getCaseDistributionLogList = getCaseDistributionLogList;
exports.getCaseIdBySingleRelationPhone = getCaseIdBySingleRelationPhone;
exports.getCaseInfoLabelsCount = getCaseInfoLabelsCount;
exports.getCaseLeaveList = getCaseLeaveList;
exports.getCaseReductionList = getCaseReductionList;
exports.getCaseRepayByCaseId = getCaseRepayByCaseId;
exports.getCaseRepayList = getCaseRepayList;
exports.getCaseUserContactsByCaseId = getCaseUserContactsByCaseId;
exports.getCaseUserContactsList = getCaseUserContactsList;
exports.getCaseUserLabelsCount = getCaseUserLabelsCount;
exports.getDoCaseLeave = getDoCaseLeave;
exports.getHtDclCount = getHtDclCount;
exports.getMyCaseSource = getMyCaseSource;
exports.getNoExecutedCampaignNum = getNoExecutedCampaignNum;
exports.getNoReadCallCampaignRecord = getNoReadCallCampaignRecord;
exports.getNotificationArr = getNotificationArr;
exports.getNotifyList = getNotifyList;
exports.getRepayList = getRepayList;
exports.getResolveCaseInfo = getResolveCaseInfo;
exports.getReturnCall = getReturnCall;
exports.getSmsAnswerList = getSmsAnswerList;
exports.getTemplateLists = getTemplateLists;
exports.getUserInfoByCaseId = getUserInfoByCaseId;
exports.getVoucherList = getVoucherList;
exports.getcaseUserLablesList = getcaseUserLablesList;
exports.getuserSuccessCase = getuserSuccessCase;
exports.importAttachmentByType = importAttachmentByType;
exports.listUser = listUser;
exports.markDeal = markDeal;
exports.notification = notification;
exports.notiiyInfo = notiiyInfo;
exports.obtainblacklistlist = obtainblacklistlist;
exports.openCallElRLPhone = openCallElRLPhone;
exports.openCallRLPhone = openCallRLPhone;
exports.outCall = outCall;
exports.outCallStopStart = outCallStopStart;
exports.repayExport = repayExport;
exports.repayExportList = repayExportList;
exports.repayListExport = repayListExport;
exports.selectBalanceById = selectBalanceById;
exports.selectSmsSendLogList = selectSmsSendLogList;
exports.selectTaskMessage = selectTaskMessage;
exports.sendTemplate = sendTemplate;
exports.smsCaseInfoList = smsCaseInfoList;
exports.sumitTaskMessage = sumitTaskMessage;
exports.sumitTaskReply = sumitTaskReply;
exports.taskMesManagerList = taskMesManagerList;
exports.taskMessageExport = taskMessageExport;
exports.taskReplyList = taskReplyList;
exports.treeselect = treeselect;
exports.treeselect1 = treeselect1;
exports.updateCallCampaignRecord = updateCallCampaignRecord;
exports.updateCaseStatus = updateCaseStatus;
exports.updateNotification = updateNotification;
exports.updateScoringRecord = updateScoringRecord;
exports.uploadAttachmentFile = uploadAttachmentFile;
exports.uploadBlockChain = uploadBlockChain;
exports.uploadCaseAdjustFile = uploadCaseAdjustFile;
exports.uploadCaseAttachmentFile = uploadCaseAttachmentFile;
exports.uploadCaseUserContacts = uploadCaseUserContacts;
exports.uploadCheckPhoneData = uploadCheckPhoneData;
exports.uploadGsFile = uploadGsFile;
exports.uploadHistoryRepayFile = uploadHistoryRepayFile;
exports.uploadOutFile = uploadOutFile;
exports.uploadUserElseContacts = uploadUserElseContacts;
exports.userTreeSelectNoPermission = userTreeSelectNoPermission;
exports.userTreeselect = userTreeselect;
var _request = _interopRequireDefault(require("@/utils/request"));
//选择任务消息
function selectTaskMessage(params) {
  return (0, _request.default)({
    url: '/taskMessInfo/selectTaskMessageByCaseId',
    params: params,
    responseType: 'application/json'
  });
}
//案件详情-添加用户标签
function addCaseUserLabel(data) {
  return (0, _request.default)({
    url: '/caseInfo/labels/add',
    method: 'post',
    data: data
  });
}

// 案件详情-查询用户借款信息
function getBorrowInfoByCaseId(query) {
  return (0, _request.default)({
    url: '/caseInfo/borrow/getBorrowInfoByCaseId',
    get: 'get',
    params: query
  });
}
// 查询部门下拉树结构
function treeselect() {
  return (0, _request.default)({
    url: '/system/dept/getDeptTreeByDeptType',
    get: 'get'
  });
}
function treeselect1() {
  return (0, _request.default)({
    url: '/system/dept/getDeptTreeAndYgByDept',
    get: 'get'
  });
}
// 查询当前用户持有部门
function userTreeselect() {
  return (0, _request.default)({
    url: '/system/dept/treeselect',
    get: 'get'
  });
}
function userTreeSelectNoPermission() {
  return (0, _request.default)({
    url: '/system/dept/treeNoPermission',
    get: 'get'
  });
}
// 案件详情-查询用户信息
function getUserInfoByCaseId(query) {
  return (0, _request.default)({
    url: '/caseInfo/user/getCaseUserInfo',
    get: 'get',
    params: query
  });
}
// 案件详情-查询用户已有标签
function getcaseUserLablesList(query) {
  return (0, _request.default)({
    url: '/caseInfo/labels/getUserLabels',
    get: 'get',
    params: query
  });
}

// 案件详情-分案记录
function getCaseDistributionLogList(query) {
  return (0, _request.default)({
    url: '/caseInfo/distributionLog/getCaseDistributionLogList',
    get: 'get',
    params: query
  });
}

//导出分案记录
function doDistributionExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/distributionLog/export',
    method: 'get',
    params: data
  });
}

// 案件详情-查询用户联系人信息
function getCaseUserContactsList(query) {
  return (0, _request.default)({
    url: '/caseInfo/contacts/getCaseUserContactsList',
    get: 'get',
    params: query
  });
}

// 案件详情-查询还款记录
function getCaseRepayByCaseId(query) {
  return (0, _request.default)({
    url: '/caseInfo/repay/getCaseRepayByCaseId',
    get: 'get',
    params: query
  });
}

// 查询用户列表
function listUser(query) {
  return (0, _request.default)({
    url: '/system/user/list',
    get: 'get',
    params: query
  });
}

// 查询案件附件
function getCaseAttachmentList(query) {
  return (0, _request.default)({
    url: '/caseInfo/attachment/getCaseAttachmentList',
    get: 'get',
    params: query
  });
}
//案件管理-案件-案件列表-管理员
function caseManagerList(data) {
  return (0, _request.default)({
    url: '/caseInfo/managerList',
    method: 'get',
    params: data
  });
}
//发送短信页面案件列表
function smsCaseInfoList(data) {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/smsCaseInfoList',
    method: 'get',
    params: data
  });
}
//案件管理-案件-案件列表-员工
function caseEmployeeList(data) {
  return (0, _request.default)({
    url: '/caseInfo/employeeList',
    get: 'get',
    params: data
  });
}
//案件管理-案件-导出
function caseManagerExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/export',
    get: 'get',
    params: data
  });
}
//案件管理-案件信息-导出
function exportCase(data) {
  return (0, _request.default)({
    url: '/caseInfo/exportCase',
    get: 'get',
    params: data
  });
}
//债务人管理-导出
function caseCustomerAttachmentExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/exportCustomerAttachment',
    get: 'get',
    params: data
  });
}

//案件管理-导出债务-导出
function caseBorrowExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/exportCaseBorrow',
    get: 'get',
    params: data
  });
}
function addCampaignData(data) {
  return (0, _request.default)({
    url: '/caseInfo/campaignRecord/addCaseInfoCampaign',
    method: 'get',
    params: data
  });
}

//案件管理-案件-更新案件状态
function updateCaseStatus(data) {
  return (0, _request.default)({
    url: '/caseInfo/updateCaseStatus',
    method: 'get',
    params: data
  });
}
//导入历史还款
function uploadHistoryRepayFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/import/importHistoryRepay',
    method: 'post',
    data: data
  });
}
// 导入附件
function uploadAttachmentFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/import/importCase',
    method: 'post',
    data: data
  });
}
// 批量公示
function uploadGsFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/import/importGsCase',
    method: 'post',
    data: data
  });
}
function uploadCaseAttachmentFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/import/importAttachment',
    method: 'post',
    data: data
  });
}
function uploadCaseAdjustFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/importCaseAdjustRecord',
    method: 'post',
    data: data
  });
}
//债务债务人出库
function uploadOutFile(data) {
  return (0, _request.default)({
    url: '/caseInfo/out/outCase',
    method: 'post',
    data: data
  });
}
//案件分派接口
function caseInfoCaseDistribution(data) {
  return (0, _request.default)({
    url: '/caseInfo/caseDistribution',
    method: 'post',
    data: data
  });
}
//案件规则分配接口
function caseInfoSmartDistribution(data) {
  return (0, _request.default)({
    url: '/caseInfo/smartDistribution',
    method: 'post',
    data: data
  });
}
//调解记录接口
function getCaseAdjustRecordByCaseId(query) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/getCaseAdjustRecordListByCaseId',
    get: 'get',
    params: query
  });
}
//添加调解记录接口
function addCaseAdjustRecord(data) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/add',
    method: 'post',
    data: data
  });
}
//添加联系人接口
function addUseContact(data) {
  return (0, _request.default)({
    url: '/caseInfo/contacts/add',
    method: 'post',
    data: data
  });
}
//修改联系人接口
function editUseContact(data) {
  return (0, _request.default)({
    url: '/caseInfo/contacts/edit',
    method: 'post',
    data: data
  });
}
//删除联系人接口
function deleteUseContact(data) {
  return (0, _request.default)({
    url: '/caseInfo/contacts/' + data,
    method: 'delete'
  });
}

//获取案件附件接口
function getCaseAttachmentListByCaseId(data) {
  return (0, _request.default)({
    url: '/caseInfo/attachment/getCaseAttachmentList',
    method: 'get',
    params: data
  });
}
//留案申请列表
function getCaseLeaveList(data) {
  return (0, _request.default)({
    url: '/caseInfo/getCaseLeaveList',
    method: 'get',
    params: data
  });
}
//留案审核处理
function getDoCaseLeave(data) {
  return (0, _request.default)({
    url: '/caseInfo/doCaseLeave',
    method: 'post',
    data: data
  });
}
//减免申请列表
function getCaseReductionList(data) {
  return (0, _request.default)({
    url: '/caseInfo/getCaseReductionList',
    method: 'get',
    params: data
  });
}
//减免审核处理
function doCaseReduction(data) {
  return (0, _request.default)({
    url: '/caseInfo/doCaseReduction',
    method: 'post',
    data: data
  });
}
//提交留案申请
function addCaseLeave(data) {
  return (0, _request.default)({
    url: '/caseInfo/addCaseLeave',
    method: 'post',
    data: data
  });
}

//提交减免申请
function addCaseReduction(data) {
  return (0, _request.default)({
    url: '/caseInfo/addCaseReduction',
    method: 'post',
    data: data
  });
}

//提交还款申请
function addCaseRepay(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/addCaseRepay',
    method: 'post',
    data: data
  });
}

//提交还款申请并且通过审核
function appletsAddCaseRepay(data, id) {
  return (0, _request.default)({
    url: '/caseInfo/repay/appletsAddCaseRepay?appletsId=' + id,
    method: 'post',
    data: data
  });
}

//提交冻结解冻
function addCaseFrozen(data) {
  return (0, _request.default)({
    url: '/caseInfo/borrow/frozen',
    method: 'post',
    params: data
  });
}

//提交结清
function addCaseEnd(data) {
  return (0, _request.default)({
    url: '/caseInfo/borrow/end',
    method: 'post',
    params: data
  });
}

//提交诉讼
function addLawsuit(data) {
  return (0, _request.default)({
    url: '/caseInfo/borrow/addLawsuit/' + data,
    method: 'PUT'
  });
}

//提交撤诉
function delLawsuit(data) {
  return (0, _request.default)({
    url: '/caseInfo/borrow/delLawsuit/' + data,
    method: 'PUT'
  });
}

//查询告知函模板列表
function getNotifyList(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/getNotificationTemplateList',
    method: 'get',
    params: data
  });
}

//新增告知函模板
function addNotification(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/addNotification',
    method: 'post',
    data: data
  });
}
//启动停用
function notification(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/able',
    method: 'get',
    params: data
  });
}
//获取告知函模板详细信息
function notiiyInfo(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/getInfo',
    method: 'get',
    params: data
  });
}
//修改告知函模板
function updateNotification(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/updateNotification',
    method: 'post',
    data: data
  });
}
//案件管理-调解记录列表
function getCaseAdjustRecordList(data) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/getCaseAdjustRecordList',
    method: 'get',
    params: data
  });
}
//案件管理-调解记录-导出
function adjustRecordExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/export',
    method: 'get',
    params: data
  });
}
//用户基本信息-点击告知函图标-展示数据
function getNotificationArr() {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/getNotificationArr',
    method: 'get'
  });
}
//用户基本信息-点击告知函图标-展示数据-模板内容
function getResolveCaseInfo(data) {
  return (0, _request.default)({
    url: '/caseInfo/notificationTemplate/getResolveCaseInfo',
    method: 'get',
    params: data
  });
}
//案件管理-冻结
function frozenOrUnfrozenCase(data) {
  return (0, _request.default)({
    url: '/caseInfo/frozenOrUnfrozenCase',
    method: 'get',
    params: data
  });
}
function batchFrozenCase(data) {
  return (0, _request.default)({
    url: '/caseInfo/import/batchFrozenCase',
    method: 'post',
    data: data
  });
}
//案件管理-创建工单
function sumitTaskMessage(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/add',
    method: 'get',
    params: data
  });
}
//消息任务-追加工单
function sumitTaskReply(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/addTaskTeply1',
    method: 'get',
    params: data
  });
}
//案件管理-还款列表
function getRepayList(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/list',
    method: 'get',
    params: data
  });
}
//案件管理-还款列表导出
function repayListExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/export',
    method: 'get',
    params: data
  });
}
//财务审核-还款列表
function getCaseRepayList(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/getCaseRepayList',
    method: 'get',
    params: data
  });
}
//财务审核-还款列表-审核
function doCaseRepay(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/doCaseRepay',
    method: 'post',
    data: data
  });
}

//财务审核-还款列表-批量审核
function doBatchCaseRepay(ids, applyStatus) {
  return (0, _request.default)({
    url: '/caseInfo/repay/doBatchCaseRepay?applyStatus=' + applyStatus + "&ids=" + ids,
    method: 'get'
  });
}

//财务审核-还款列表-导出
function repayExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/exportRepayApply',
    method: 'get',
    params: data
  });
}
//财务审核-还款列表-导出
function repayExportList(data) {
  return (0, _request.default)({
    url: '/caseInfo/repay/exportRepayApplyList',
    method: 'get',
    params: data
  });
}
//财务审核-减免列表-导出
function doCaseReductionExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/exportReduction',
    method: 'get',
    params: data
  });
}
//案件-案件详情-查询历史委托记录列表
function entrustHistoryList(data) {
  return (0, _request.default)({
    url: '/caseInfo/entrustHistory/list',
    method: 'get',
    params: data
  });
}
//案件-分案导入
function batchCaseDistribution(data, a, b) {
  return (0, _request.default)({
    url: '/caseInfo/batchCaseDistribution?type=' + a + "&caseSource=" + b,
    method: 'post',
    data: data
  });
}
//案件-案件详情-银行卡信息
function getCaseBankInfoList(data) {
  return (0, _request.default)({
    url: '/caseInfo/bankInfo/getCaseBankInfoList',
    method: 'get',
    params: data
  });
}
//案件-新增调解记录-下拉框显示联系人
function getCaseUserContactsByCaseId(data) {
  return (0, _request.default)({
    url: '/caseInfo/contacts/getCaseUserContactsByCaseId',
    method: 'get',
    params: data
  });
}

//案件管理-债权方
function channelList(data) {
  return (0, _request.default)({
    url: '/caseInfo/channel/list',
    method: 'get',
    params: data
  });
}
//案件管理-债权方-新增
function channelSaveChannel(data) {
  return (0, _request.default)({
    url: '/caseInfo/channel/saveChannel',
    method: 'post',
    data: data
  });
}
//案件管理-债权方-修改
function channelUpdateChannel(data) {
  return (0, _request.default)({
    url: '/caseInfo/channel/updateChannel',
    method: 'post',
    data: data
  });
}
//案件管理-债权方-冻结渠道
function channelFronzenChannel(data) {
  return (0, _request.default)({
    url: '/caseInfo/channel/fronzenChannel',
    method: 'get',
    params: data
  });
}
//案件管理-债权方-查看
function caseInfoChannel(data) {
  return (0, _request.default)({
    url: '/caseInfo/channel/getImportHistory',
    method: 'get',
    params: data
  });
}
//案件管理-债权方-导出
function channelExport() {
  return (0, _request.default)({
    url: '/caseInfo/channel/export',
    method: 'get'
  });
}
//留案审核-导出
function caseLeaveExport(data) {
  return (0, _request.default)({
    url: '/caseInfo/leaveExport',
    method: 'get',
    params: data
  });
}

//其他凭证列表
function getVoucherList(data) {
  return (0, _request.default)({
    url: '/caseInfo/attachment/getOtherVoucherList',
    method: 'get',
    params: data
  });
}
//评分
function updateScoringRecord(data) {
  return (0, _request.default)({
    url: '/caseInfo/adjustRecord/update',
    method: 'post',
    data: data
  });
}

//联系人导入
function uploadCaseUserContacts(data) {
  return (0, _request.default)({
    url: '/caseInfo/uploadCaseUserContacts',
    method: 'post',
    data: data
  });
}

//联系人导入
function uploadUserElseContacts(data) {
  return (0, _request.default)({
    url: '/caseInfo/uploadUserElseContacts',
    method: 'post',
    data: data
  });
}

//机器人离线消息
function getNoReadCallCampaignRecord(data) {
  return (0, _request.default)({
    url: '/caseInfo/campaignRecord/getNoReadCallCampaignRecord',
    method: 'get',
    params: data
  });
}
//修改机器人消息状态
function updateCallCampaignRecord(data) {
  return (0, _request.default)({
    url: '/caseInfo/campaignRecord/updateCallCampaignRecord',
    method: 'post',
    data: data
  });
}

//数据上链
function uploadBlockChain(data) {
  //console.log("requrl == " + '/caseInfo/blockChain/uploadBlockChain?caseId='+data.caseId + "&"+data.key + "="+data[data.key] + "&blockType="+data.blockType);
  return (0, _request.default)({
    url: '/caseInfo/blockChain/uploadBlockChain?caseId=' + data.caseId + "&" + data.key + "=" + data[data.key] + "&blockType=" + data.blockType,
    method: 'post'
  });
}
//调解成功数数
function getuserSuccessCase(data) {
  return (0, _request.default)({
    url: '/caseInfo/homePage/userSuccessCase',
    method: 'get',
    params: data
  });
}

//余额数
function selectBalanceById(data) {
  return (0, _request.default)({
    url: '/caseInfo/homePage/selectBalanceById',
    method: 'get',
    params: data
  });
}

//待处理数量
function getHtDclCount() {
  return (0, _request.default)({
    url: '/taskMessInfo/getHtDclCount',
    method: 'get'
  });
}

/**
 * 空号检测数据上传
 * @param data
 */
function uploadCheckPhoneData(data) {
  return (0, _request.default)({
    url: '/caseInfo/uploadCheckPhoneData',
    method: 'post',
    data: data
  });
}

//安心签-开户发送验证码
function companyAccount(id) {
  return (0, _request.default)({
    url: '/caseInfo/sign/companyAccount?caseChannelId=' + id,
    method: 'get'
  });
}
//根据模板发送短信
function sendTemplate(data) {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/sendTemplate',
    method: 'post',
    data: data
  });
}
//查询模板列表
function getTemplateLists() {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/getTemplateList',
    method: 'get'
  });
}
//预外呼请求接口
function outCall(data) {
  return (0, _request.default)({
    url: '/caseInfo/personalCampaign?caseIds=' + data.caseIds + '&type=' + data.type + '',
    method: 'post'
  });
}
//预外呼请求-暂停
function outCallStopStart(data) {
  return (0, _request.default)({
    url: 'caseInfo/personalCampaignStatus',
    method: 'get',
    params: data
  });
}

//查询labelCount
function getCaseUserLabelsCount(data) {
  return (0, _request.default)({
    url: 'caseInfo/labels/getCaseUserLabelsCount',
    method: 'get',
    params: data
  });
}

//查询infoLabelCount
function getCaseInfoLabelsCount(data) {
  return (0, _request.default)({
    url: 'caseInfo/getCaseInfoLabelsCount',
    method: 'get',
    params: data
  });
}

//删除标签
function deleteTag(data) {
  return (0, _request.default)({
    url: 'caseInfo/labels/delete/' + data,
    method: 'get'
  });
}

//案件退回
function backCase(data) {
  return (0, _request.default)({
    url: '/caseInfo/backCase?caseIds=' + data.caseIds,
    method: 'post'
  });
}
//预外呼查看详情
function getCaseIdBySingleRelationPhone(data) {
  return (0, _request.default)({
    url: 'caseInfo/user/getCaseIdBySingleRelationPhone',
    method: 'get',
    params: data
  });
}
//预外呼剩余未拨打
function getNoExecutedCampaignNum() {
  return (0, _request.default)({
    url: 'caseInfo/getNoExecutedCampaignNum',
    method: 'get'
  });
}

//案件附件上传
function importAttachmentByType(data) {
  return (0, _request.default)({
    url: '/caseInfo/backCase',
    method: 'post',
    data: data
  });
}

//查询回拨电话
function getReturnCall() {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/getReturnCall',
    method: 'get'
  });
}

//查案件来源
function getMyCaseSource() {
  return (0, _request.default)({
    url: '/caseInfo/getMyCaseSource',
    method: 'get'
  });
}

//申请公示
function applyGs(data) {
  return (0, _request.default)({
    url: '/caseInfo/applyGs?caseIds=' + data.caseIds,
    method: 'post'
  });
}

//生成调解告知函
function createTctjgzh(data) {
  return (0, _request.default)({
    url: '/caseInfo/createTctjgzh?caseIds=' + data.caseIds,
    method: 'post'
  });
}

//短信回复列表
function getSmsAnswerList(data) {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/getSmsAnswerList',
    method: 'get',
    params: data
  });
}

//短信回复列表
function selectSmsSendLogList(data) {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/getSmsSendLog',
    method: 'get',
    params: data
  });
}

//拨打电话
function openCallRLPhone(data) {
  return (0, _request.default)({
    url: "/caseInfo/smsTemplate/openCallRLPhone?phone=" + data.phone + '&type=' + data.type + '&caseId=' + data.caseId,
    method: 'post',
    data: data
  });
}
//拨打电话返回的状态码
function getCallRecordByCallUuid(data) {
  // console.log(data,'api接口',88888888888888)
  return (0, _request.default)({
    url: '/caseInfo/record/getCallRecordByCallUuid',
    method: 'get',
    params: data
  });
}
//根据模板发送短信
function openCallElRLPhone(data) {
  return (0, _request.default)({
    url: '/caseInfo/smsTemplate/openCallRLPhone?phone=' + data.relationPhone,
    method: 'post',
    data: data
  });
}
//消息任务管理列表
function taskMesManagerList(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/managerList',
    method: 'get',
    params: data
  });
}
//回复内容列表
function taskReplyList(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/selectTaskReply?taskId=' + data.id,
    method: 'get',
    params: data
  });
}

//消息任务-导出
function taskMessageExport(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/messageExport',
    method: 'get',
    params: data
  });
}

//标记已处理
function markDeal(data) {
  return (0, _request.default)({
    url: '/taskMessInfo/markDealMessage',
    method: 'post',
    data: data
  });
}

// 获取黑名单列表
function obtainblacklistlist(data) {
  return (0, _request.default)({
    url: '/black/list',
    method: 'get',
    params: data
  });
}
// 添加黑名单账号
function addobtainblacklistlist(data) {
  // console.log(data,99999999990000);
  return (0, _request.default)({
    url: '/black/addPhone?phone=' + data.phone + '&remark=' + data.remark,
    method: 'post'
    // data: data
  });
}
// 清除案件协议状态
function caseInfoclearAdjusUrl(data) {
  return (0, _request.default)({
    url: '/caseInfo/clearAdjusUrl',
    method: 'get',
    params: data
  });
}