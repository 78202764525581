"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUser = addUser;
exports.authAndImage = authAndImage;
exports.changeUserStatus = changeUserStatus;
exports.delUser = delUser;
exports.exportUser = exportUser;
exports.getUser = getUser;
exports.getUserAssessmentByUserId = getUserAssessmentByUserId;
exports.getUserProfile = getUserProfile;
exports.importTemplate = importTemplate;
exports.listUser = listUser;
exports.listUserAssessment = listUserAssessment;
exports.ownerAddSeal = ownerAddSeal;
exports.ownerCheckCode = ownerCheckCode;
exports.resetUserPwd = resetUserPwd;
exports.signAdmin = signAdmin;
exports.signCheckCode = signCheckCode;
exports.updateUser = updateUser;
exports.updateUserAssessment = updateUserAssessment;
exports.updateUserProfile = updateUserProfile;
exports.updateUserPwd = updateUserPwd;
exports.uploadAvatar = uploadAvatar;
exports.uploadmyAvatar = uploadmyAvatar;
var _request = _interopRequireDefault(require("@/utils/request"));
var _ruoyi = require("@/utils/ruoyi");
// 查询用户列表
function listUser(query) {
  return (0, _request.default)({
    url: '/system/user/list',
    method: 'get',
    params: query
  });
}

//查询用户绩效使用
function listUserAssessment(query) {
  return (0, _request.default)({
    url: '/caseInfo/assessment/userList',
    method: 'get',
    params: query
  });
}

// 查询用户详细
function getUser(userId) {
  return (0, _request.default)({
    url: '/system/user/' + (0, _ruoyi.praseStrEmpty)(userId),
    method: 'get'
  });
}

// 新增用户
function addUser(data) {
  return (0, _request.default)({
    url: '/system/user',
    method: 'post',
    data: data
  });
}

// 修改用户
function updateUser(data) {
  return (0, _request.default)({
    url: '/system/user',
    method: 'put',
    data: data
  });
}

// 删除用户
function delUser(userId) {
  return (0, _request.default)({
    url: '/system/user/' + userId,
    method: 'delete'
  });
}

// 导出用户
function exportUser(query) {
  return (0, _request.default)({
    url: '/system/user/export',
    method: 'get',
    params: query
  });
}
// 安心签入户
function signAdmin(userId) {
  return (0, _request.default)({
    url: '/caseInfo/sign/accountSendCode?userId=' + userId,
    method: 'get'
  });
}
// 安心签调解员短信验证
function signCheckCode(userId, value) {
  return (0, _request.default)({
    url: '/caseInfo/sign/checkCode?userId=' + userId + '&checkCode=' + value,
    method: 'get'
  });
}
// 安心签甲方短信验证
function ownerCheckCode(id, value) {
  return (0, _request.default)({
    url: '/caseInfo/sign/ownerCheckCode?id=' + id + '&checkCode=' + value,
    method: 'get'
  });
}
// 安心签甲方授权
function ownerAddSeal(file, caseChannelId) {
  return (0, _request.default)({
    url: '/caseInfo/sign/addSeal?caseChannelId=' + caseChannelId,
    method: 'post',
    data: file
  });
}
// 用户密码重置
function resetUserPwd(userId, password) {
  var data = {
    userId: userId,
    password: password
  };
  return (0, _request.default)({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  });
}

// 用户状态修改
function changeUserStatus(userId, status) {
  var data = {
    userId: userId,
    status: status
  };
  return (0, _request.default)({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  });
}

// 查询用户个人信息
function getUserProfile() {
  return (0, _request.default)({
    url: '/system/user/profile',
    method: 'get'
  });
}

// 修改用户个人信息
function updateUserProfile(data) {
  return (0, _request.default)({
    url: '/system/user/profile',
    method: 'put',
    data: data
  });
}

// 用户密码重置
function updateUserPwd(oldPassword, newPassword) {
  var data = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return (0, _request.default)({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  });
}

// 用户头像上传
function uploadAvatar(data) {
  return (0, _request.default)({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  });
}

// 用户头像上传
function uploadmyAvatar(data) {
  return (0, _request.default)({
    url: '/common/uploadToQny',
    method: 'post',
    data: data
  });
}

// 下载用户导入模板
function importTemplate() {
  return (0, _request.default)({
    url: '/system/user/importTemplate',
    method: 'get'
  });
}

// 获取用户绩效数据
function getUserAssessmentByUserId(userId) {
  return (0, _request.default)({
    url: '/caseInfo/assessment/' + userId,
    method: 'get'
  });
}

//更新绩效指标
function updateUserAssessment(data) {
  return (0, _request.default)({
    url: '/caseInfo/assessment/edit',
    method: 'post',
    data: data
  });
}
function authAndImage(data) {
  return (0, _request.default)({
    url: '/system/user/authAndImage',
    method: 'post',
    data: data
  });
}