"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  name: 'RuoYiGit',
  data: function data() {
    return {
      url: 'https://gitee.com/y_project/RuoYi-Vue'
    };
  },
  methods: {
    goto: function goto() {
      window.open(this.url);
    }
  }
};