"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/www/wwwroot/zhiyunyi-web/node_modules/core-js/modules/es6.array.iterator.js");
require("/www/wwwroot/zhiyunyi-web/node_modules/core-js/modules/es6.promise.js");
require("/www/wwwroot/zhiyunyi-web/node_modules/core-js/modules/es6.object.assign.js");
require("/www/wwwroot/zhiyunyi-web/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
require("@/assets/styles/index.scss");
require("@/assets/styles/ruoyi.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
require("./assets/icons");
require("./permission");
var _data = require("@/api/system/dict/data");
var _config = require("@/api/system/config");
var _ruoyi2 = require("@/utils/ruoyi");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
require("./assets/font/font.css");
var _mavonEditor = _interopRequireDefault(require("mavon-editor"));
require("mavon-editor/dist/css/index.css");
var _htmlToPdf = _interopRequireDefault(require("@/utils/htmlToPdf"));
var _vCharts = _interopRequireDefault(require("v-charts"));
var _vueWebsocket = _interopRequireDefault(require("vue-websocket"));
var _Practice = _interopRequireDefault(require("@/views/Practice.vue"));
// a modern alternative to CSS resets

// global css
// ruoyi css

// icon
// permission control

// 水印
// import watermark from './utils/watermark.js'
// Vue.prototype.$watermark = watermark
// import './utils/watermark.js';
// 全局方法挂载
_vue.default.prototype.getDicts = _data.getDicts;
_vue.default.prototype.getConfigKey = _config.getConfigKey;
_vue.default.prototype.parseTime = _ruoyi2.parseTime;
_vue.default.prototype.resetForm = _ruoyi2.resetForm;
_vue.default.prototype.addDateRange = _ruoyi2.addDateRange;
_vue.default.prototype.selectDictLabel = _ruoyi2.selectDictLabel;
_vue.default.prototype.download = _ruoyi2.download;
_vue.default.prototype.handleTree = _ruoyi2.handleTree;
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};
_vue.default.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    var storage = {
      setItem: function setItem(k, val) {
        localStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  }
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载---测试组件
_vue.default.component('Pagination', _Pagination.default);
// import AudioPlayer from '@liripeng/vue-audio-player'
// import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
_vue.default.use(_permission.default);
// Vue.use(AudioPlayer)

_vue.default.use(_mavonEditor.default);
_vue.default.use(_htmlToPdf.default);
_vue.default.use(_vCharts.default);
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'
// Vue.use(VueAwesomeSwiper)
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
_vue.default.directive('down', {
  inserted: function inserted(el, binding) {
    el.style.cssText = 'cursor: pointer;color:#409EFF;font-size:12px;';
    el.addEventListener('click', function () {
      var link = document.createElement('a');
      var url = binding.value;
      // 这里是将url转成blob地址，
      fetch(url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob);
        link.download = '';
        document.body.appendChild(link);
        link.click();
      });
    });
  }
});
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
_vue.default.config.productionTip = false;
// bus总线程
_vue.default.prototype.bus = new _vue.default();
// var vm=new Vue({
//   el: '#app',
//   router,
//   store,
//   render: h => h(App)
// })

// 根据路由名修改主入口页面
var mainentrance = {
  render: function render(h) {
    return h(_App.default);
  }
};
if (window.location.pathname == '/t') mainentrance = {
  render: function render(h) {
    return h(_Practice.default);
  }
};else mainentrance = {
  render: function render(h) {
    return h(_App.default);
  }
};
var vm = new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: mainentrance.render
});