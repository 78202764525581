exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#e3e3e3",
	"menuBg": "rgba(48,65,86,0.1)",
	"menuHover": "rgba(38,52,69,0.7)",
	"subMenuBg": "rgba(31,45,61,0.6)",
	"subMenuHover": "rgba(0,21,40,0.7)",
	"sideBarWidth": "200px"
};