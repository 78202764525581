"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _vue = _interopRequireDefault(require("vue"));
var _statusInfo = require("@/api/statusInfo.js");
//电话状态

_vue.default.prototype.phoneStatus = [{
  dictLabel: '正常',
  dictValue: 1
}, {
  dictLabel: '停机',
  dictValue: 2
}, {
  dictLabel: '关机',
  dictValue: 3
}, {
  dictLabel: '查询失败',
  dictValue: 4
}];
//评分单选题
_vue.default.prototype.giveList = [{
  label: '服务用语',
  id: '1',
  data: [{
    label: '首通电话核资',
    id: '1-1',
    radio: 'fwradio11',
    data: [{
      label: '完整、清晰核实信息（5分）',
      id: '1-1-1',
      value: '5'
    }, {
      label: '核实不清（3分）',
      id: '1-1-2',
      value: '3'
    }, {
      label: '未核实（0分）',
      id: '1-1-3',
      value: '0'
    }]
  }, {
    label: '身份表明',
    id: '1-2',
    radio: 'fwradio12',
    data: [{
      label: '表明身份，语气亲切温和（5分）',
      id: '1-2-1',
      value: '5'
    }, {
      label: '表明词语混乱（3分）',
      id: '1-2-2',
      value: '3'
    }, {
      label: '未做身份表明（0分）',
      id: '1-2-3',
      value: '0'
    }]
  }, {
    label: '礼貌用语',
    id: '1-3',
    radio: 'fwradio13',
    data: [{
      label: '适时、正确使用礼貌用语（您、请、抱歉、谢谢、不客气、您好等），整通电话中至少有一次姓氏称呼（5分）',
      id: '1-3-1',
      value: '5'
    }, {
      label: '表明词语混乱（3分）',
      id: '1-3-2',
      value: '3'
    }, {
      label: '未做身份表明（0分）',
      id: '1-3-3',
      value: '0'
    }]
  }, {
    label: '音量及语速',
    id: '1-4',
    radio: 'fwradio14',
    data: [{
      label: '音量适中、语速适中、普通话标准、吐字清晰（5分）',
      id: '1-4-1',
      value: '5'
    }, {
      label: '音量、语速及吐字项无较大失误，基本可以保证正常沟通（3分）',
      id: '1-4-2',
      value: '3'
    }, {
      label: '客户表示听不懂、听不清楚（0分）',
      id: '1-4-3',
      value: '0'
    }]
  }, {
    label: '禁语',
    id: '1-5',
    radio: 'fwradio15',
    data: [{
      label: '未出现口头语或禁语（5分）',
      id: '1-5-1',
      value: '5'
    }, {
      label: '使用不当话术（3分）',
      id: '1-5-2',
      value: '3'
    }, {
      label: '使用禁语（0分）',
      id: '1-5-3',
      value: '0'
    }]
  }, {
    label: '调解态度',
    id: '1-6',
    radio: 'fwradio16',
    data: [{
      label: '积极耐心引导（5分）',
      id: '1-6-1',
      value: '5'
    }, {
      label: '一般态度跟进（3分）',
      id: '1-6-2',
      value: '3'
    }, {
      label: '沟通较强势，态度恶劣（0分）',
      id: '1-6-3',
      value: '0'
    }]
  }]
}, {
  label: '沟通能力',
  id: '2',
  data: [{
    label: '理解力、倾听力',
    id: '2-1',
    radio: 'gtradio21',
    data: [{
      label: '准确、快速理解客户意图（10分）',
      id: '2-1-1',
      value: '10'
    }, {
      label: '经2次询问后理解客户意图（5分）',
      id: '2-1-2',
      value: '5'
    }, {
      label: '错误判断客户意图/未询问/3次以上询问（0分）',
      id: '2-1-3',
      value: '0'
    }]
  }, {
    label: '表达能力',
    id: '2-2',
    radio: 'gtradio22',
    data: [{
      label: '清晰流畅解答客户问题（10分）',
      id: '2-2-1',
      value: '10'
    }, {
      label: '语言随意、表达不严谨、出现不规则声音、出现通话空白（如小声计算、自言自语、与旁边坐席对话） （5分）',
      id: '2-2-2',
      value: '5'
    }, {
      label: '表达啰嗦、逻辑思维混乱、磕巴（0分）',
      id: '2-2-3',
      value: '0'
    }]
  }, {
    label: '服务态度',
    id: '2-3',
    radio: 'gtradio23',
    data: [{
      label: '服务态度积极、热情、主动（10分）',
      id: '2-3-1',
      value: '10'
    }, {
      label: '服务态度敷衍，与客户沟通形式化（5分）',
      id: '2-3-2',
      value: '5'
    }, {
      label: '冷淡、急于挂机、催促客户；服务态度恶劣；与客户争吵、对骂；质问、反问、训斥客户（0分）',
      id: '2-3-3',
      value: '0'
    }]
  }]
}, {
  label: '业务知识',
  id: '3',
  data: [{
    label: '拨号结果标记',
    id: '3-1',
    radio: 'ywradio31',
    data: [{
      label: '结果状态标记无误（5分）',
      id: '3-1-1',
      value: '5'
    }, {
      label: '结果状态标记错误（0分）',
      id: '3-1-2',
      value: '0'
    }]
  }, {
    label: '调解结果标记',
    id: '3-2',
    radio: 'ywradio32',
    data: [{
      label: '结果状态标记无误（5分）',
      id: '3-2-1',
      value: '5'
    }, {
      label: '结果状态标记错误（0分）',
      id: '3-2-3',
      value: '0'
    }]
  }, {
    label: '调解记录',
    id: '3-3',
    radio: 'ywradio33',
    data: [{
      label: '完整、规范的填写（10分）',
      id: '3-3-1',
      value: '10'
    }, {
      label: '填写不完整/不规范（5分）',
      id: '3-3-2',
      value: '5'
    }, {
      label: '无调解记录（0分）',
      id: '3-3-3',
      value: '0'
    }]
  }, {
    label: '业务知识解答',
    id: '3-4',
    radio: 'ywradio34',
    data: [{
      label: '客户还款意愿引导（10分）',
      id: '3-4-1x10',
      value: '10'
    }, {
      label: '客户疑问处理（5分）',
      id: '3-4-2x5',
      value: '5'
    }, {
      label: '邀约跟进（时间及联系方式确认）（5分）',
      id: '3-4-4x5',
      value: '5'
    }, {
      label: '以上均无（0分）',
      id: '3-4-5x0',
      value: '0'
    }]
  }]
}];
_vue.default.prototype.go = function () {
  console.log(111);
};
//法院
(0, _statusInfo.callCampaignTag)().then(function (response) {
  _vue.default.prototype.callCampaign = response.data;
});