"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.string.starts-with");
var _objectSpread2 = _interopRequireDefault(require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _elementUi = require("element-ui");
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
var _mUtils = require("@/utils/mUtils");
_nprogress.default.configure({
  showSpinner: false
});
var whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/loginRes'];
_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  window.scroll(0, 0);
  // console.log(getToken(),'getToken')
  // return
  if (to.path == '/t') return next();
  if ((0, _auth.getToken)()) {
    require('@/utils/dictionaries.js');
    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetInfo').then(function (res) {
          var accountId = res.phoneLoginInfo.account_id;
          var token = res.phoneLoginInfo.token;
          var dyCti = document.getElementById("dyCti"); //度言
          // dyCti.src='https://cti.duyansoft.com?account_id='+accountId+'&token='+token//度言
          // 拉取user_info
          var roles = res.roles;
          _store.default.dispatch('GenerateRoutes', {
            roles: roles
          }).then(function (accessRoutes) {
            // 测试 默认静态页面
            // store.dispatch('permission/generateRoutes', { roles }).then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            // console.log('--------addRoutes------')
            // if (to.name === 'Casedetail') {
            //   console.log(to)
            //   console.log('Casedetail')
            // }
            _router.default.addRoutes(accessRoutes); // 动态添加可访问路由表
            next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成
          });
        }).catch(function (err) {
          _elementUi.Message.error(err);
          // store.dispatch('FedLogOut').then(() => {
          //   Message.error(err)
          //   next({ path: '/' })
          // })
        });
      } else {
        next();
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      var host = location.host;
      if (host.indexOf("eco") == -1) {
        next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      } else {
        next("/loginRes?redirect=".concat(to.path)); // 否则全部重定向到登录页
      }
      _nprogress.default.done();
    }
  }
});
_router.default.afterEach(function () {
  _nprogress.default.done();
  if (to.fullPath.startsWith('/casedetail')) {
    window.stop();
  }
});