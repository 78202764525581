"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var globalpop = {
  namespaced: true,
  state: {
    outbound: "fffffff",
    visible7: false,
    calltype7: false,
    userData: null,
    lianluo9show: ""
  },
  mutations: {
    setuserdata: function setuserdata(state, payload) {
      state.userData = payload;
    },
    setvisible7: function setvisible7(state, payload) {
      // console.log(payload)
      state.visible7 = payload;
    },
    setcalltype7: function setcalltype7(state, payload) {
      // console.log(payload)
      state.calltype7 = payload;
    },
    setflag: function setflag(state, payload) {
      // console.log(payload)
      state.flag = payload;
    },
    setlianluo9show: function setlianluo9show(state, payload) {
      // console.log(payload)
      state.lianluo9show = payload;
    }
  }
};
var _default = exports.default = globalpop;