"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddressType = getAddressType;
exports.getCallCampaignTag = getCallCampaignTag;
exports.getCallLogRecordOutcome = getCallLogRecordOutcome;
exports.getCallLogRecordType = getCallLogRecordType;
exports.getCaseAttachmentFrontConfig = getCaseAttachmentFrontConfig;
exports.getCaseBorrowStatus = getCaseBorrowStatus;
exports.getCaseLabel = getCaseLabel;
exports.getCurrentCustomerState = getCurrentCustomerState;
exports.getDialRelation = getDialRelation;
exports.getExportLogStatus = getExportLogStatus;
exports.getIndustrialPark = getIndustrialPark;
exports.getPhoneType = getPhoneType;
exports.getRepayResult = getRepayResult;
exports.getRepayType = getRepayType;
exports.getRobotResult = getRobotResult;
exports.getTemplateCallBack = getTemplateCallBack;
exports.getadjustResult = getadjustResult;
exports.getadjustType = getadjustType;
exports.getcaseReductionApplyStatus = getcaseReductionApplyStatus;
exports.getcaseRepayApplystatuss = getcaseRepayApplystatuss;
exports.getcaseSource = getcaseSource;
exports.getcaseStayApplyStatus = getcaseStayApplyStatus;
exports.getcaseUserLabels = getcaseUserLabels;
exports.getcasestatus = getcasestatus;
exports.getchannelStatus = getchannelStatus;
exports.getcollectioncasestatus = getcollectioncasestatus;
exports.getconfig = getconfig;
exports.getcontractStates = getcontractStates;
exports.getdDistributionLogType = getdDistributionLogType;
exports.getdialRelation = getdialRelation;
exports.getdialResult = getdialResult;
exports.getsupportDeduction = getsupportDeduction;
var _statusInfo = require("@/api/statusInfo.js");
var _elementUi = require("element-ui");
var _statusInfo2 = require("@/api/statusInfo");
//获取前端附件类型使用
function getCaseAttachmentFrontConfig() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseAttachmentFrontConfig)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}

//获取还款类型
function getRepayType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.repayType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取客户标签
function getdDistributionLogType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.distributionLogType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取客户标签
function getDialRelation() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.dialRelation)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}

//获取客户标签
function getcaseUserLabels() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseUserLabels)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取调解结果
function getadjustResult() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.adjustResult)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取拨号结果
function getdialResult() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.dialResult)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取拨号结果
function getadjustType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.adjustType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取案件状态
function getcasestatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.casestatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取还款结果
function getRepayResult() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.repayResult)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取案件来源
function getcaseSource() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseSource)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取合同状态
function getcontractStates() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo2.contractStates)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//调解记录-案件状态
function getcollectioncasestatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.collectioncasestatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
// 调解记录-拨打关系
function getdialRelation() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.dialRelation)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
// 调解记录-债务人跟进状态
function getCurrentCustomerState() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.currentCustomerState)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
// 电话类型
function getPhoneType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.phoneType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
// 地址类型
function getAddressType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.addressType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}

//获取系统参数
function getconfig(configKey) {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.getconfigKey)(configKey).then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//留案申请状态
function getcaseStayApplyStatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseStayApplyStatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//还款审核状态
function getcaseRepayApplystatuss() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseRepayApplystatuss)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//减免审核状态
function getcaseReductionApplyStatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseReductionApplyStatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//借款状态
function getCaseBorrowStatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseBorrowStatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//是否支持划扣
function getsupportDeduction() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.supportDeduction)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//是否支持划扣
function getchannelStatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.channelStatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}

//数据导出状态
function getExportLogStatus() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.exportLogStatus)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}

//呼叫结果
function getCallLogRecordOutcome() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.callLogRecordOutcome)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//外呼类型
function getCallLogRecordType() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.callLogRecordType)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
function getRobotResult() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.robotResult)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//机器人客户标签
function getCallCampaignTag() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.callCampaignTag)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//短信回电
function getTemplateCallBack() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo2.templateCallBack)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取地区
function getIndustrialPark() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo2.industrialPark)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}
//获取案件标签
function getCaseLabel() {
  return new Promise(function (resolve, reject) {
    (0, _statusInfo.caseLabel)().then(function (res) {
      if (res.code == 200) {
        resolve(res.data);
      }
    }).catch(function (error) {
      _elementUi.Message.error("请求失败：" + error.msg);
    });
  });
}