"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _qs = _interopRequireDefault(require("qs"));
_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 90000
});
function vialeParam(param) {
  var params = new Object();
  for (var i in param) {
    if (param[i] == null || param[i] == undefined) {
      continue;
    }
    params[i] = param[i];
  }
  return params;
}
// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  ;
  if (config.method === 'get') {
    //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function () {
      var params = vialeParam(config.params);
      return _qs.default.stringify(params, {
        arrayFormat: 'repeat'
      }, {
        skipNulls: true
      });
    };
  }
  ;
  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (res) {
  if (res.config.url == '/api/taskMessInfo/selectTaskMessageByCaseId') return res.data;
  var code = res.data.code;
  if (code === 401) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code !== 200) {
    _elementUi.Notification.error({
      title: res.data.msg
    });
    return Promise.reject('error');
  } else {
    return res.data;
  }
}, function (error) {
  console.log('err' + error);
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 20 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;