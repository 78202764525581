"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportLogStatus = exports.distributionLogType = exports.dialResult = exports.dialRelation = exports.currentCustomerState = exports.contractStates = exports.collectioncasestatus = exports.channelStatus = exports.casestatus = exports.caseUserLabels = exports.caseStayApplyStatus = exports.caseSource = exports.caseRepayApplystatuss = exports.caseReductionApplyStatus = exports.caseLabel = exports.caseBorrowStatus = exports.caseAttachmentFrontConfig = exports.callLogRecordType = exports.callLogRecordOutcome = exports.callCampaignTag = exports.adjustType = exports.adjustResult = exports.addressType = void 0;
exports.getconfigKey = getconfigKey;
exports.templateCallBack = exports.supportDeduction = exports.robotResult = exports.repayType = exports.repayResult = exports.phoneType = exports.industrialPark = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
//案件管理-案件-开始
// 案件状态
var casestatus = exports.casestatus = function casestatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_info_status',
    method: 'get'
  });
};
//获取客户标签
var caseUserLabels = exports.caseUserLabels = function caseUserLabels() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_user_labels',
    method: 'get'
  });
};
//获取案件来源

var caseSource = exports.caseSource = function caseSource() {
  return (0, _request.default)({
    url: '/caseInfo/channel/getCaseChannel',
    method: 'get'
  });
};
//获取还款结果
var repayResult = exports.repayResult = function repayResult() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_info_repay_result',
    method: 'get'
  });
};
//获取拨号结果
var dialResult = exports.dialResult = function dialResult() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_adjust_record_dial_result',
    method: 'get'
  });
};
//获取拨号结果
var adjustType = exports.adjustType = function adjustType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_adjust_record_adjust_type',
    method: 'get'
  });
};
//获取调解结果
var adjustResult = exports.adjustResult = function adjustResult() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_adjust_record_adjust_result',
    method: 'get'
  });
};

//获取系统参数--下载模板
function getconfigKey(configKey) {
  return (0, _request.default)({
    url: '/system/config/configKey/' + configKey,
    method: 'get'
  });
}
// 调解记录-案件状态
var collectioncasestatus = exports.collectioncasestatus = function collectioncasestatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_collection_record_dial_status',
    method: 'get'
  });
};
// 调解记录-拨打关系
var dialRelation = exports.dialRelation = function dialRelation() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_adjust_record_dial_relation',
    method: 'get'
  });
};
// 调解记录-债务人跟进状态
var currentCustomerState = exports.currentCustomerState = function currentCustomerState() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_follow_up_status',
    method: 'get'
  });
};
// 电话类型
var phoneType = exports.phoneType = function phoneType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/cas_dianhualeixing',
    method: 'get'
  });
};
// 地址类型
var addressType = exports.addressType = function addressType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/cas_dizhileixing',
    method: 'get'
  });
};

// 分案记录-类型
var distributionLogType = exports.distributionLogType = function distributionLogType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_distribution_log_type',
    method: 'get'
  });
};

// 分案记录-类型
var repayType = exports.repayType = function repayType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_repay_type',
    method: 'get'
  });
};

// 案件附件-显示附件类型使用
var caseAttachmentFrontConfig = exports.caseAttachmentFrontConfig = function caseAttachmentFrontConfig() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_attachment_front_config',
    method: 'get'
  });
};
//留案审核状态
var caseStayApplyStatus = exports.caseStayApplyStatus = function caseStayApplyStatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_stay_apply_status',
    method: 'get'
  });
};
//还款审核状态
var caseRepayApplystatuss = exports.caseRepayApplystatuss = function caseRepayApplystatuss() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_repay_apply_status',
    method: 'get'
  });
};
//减免审核状态
var caseReductionApplyStatus = exports.caseReductionApplyStatus = function caseReductionApplyStatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_reduction_apply_status',
    method: 'get'
  });
};
//借款状态
var caseBorrowStatus = exports.caseBorrowStatus = function caseBorrowStatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/cas_task_code_status',
    method: 'get'
  });
};
//是否支持划扣
var supportDeduction = exports.supportDeduction = function supportDeduction() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_info_support_deduction',
    method: 'get'
  });
};
//债权方-状态
var channelStatus = exports.channelStatus = function channelStatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_channel_status',
    method: 'get'
  });
};

//债权方-状态
var exportLogStatus = exports.exportLogStatus = function exportLogStatus() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/export_log_status',
    method: 'get'
  });
};

//呼叫结果
var callLogRecordOutcome = exports.callLogRecordOutcome = function callLogRecordOutcome() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/call_log_record_outcome',
    method: 'get'
  });
};

//呼叫结果
var callLogRecordType = exports.callLogRecordType = function callLogRecordType() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/call_log_record_type',
    method: 'get'
  });
};
//机器人客户标签
var callCampaignTag = exports.callCampaignTag = function callCampaignTag() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/call_campaign_record_tag',
    method: 'get'
  });
};
var robotResult = exports.robotResult = function robotResult() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/call_campaign_record_outcome',
    method: 'get'
  });
};
//获取合同状态
var contractStates = exports.contractStates = function contractStates() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/contract_state',
    method: 'get'
  });
};
//短信模板回电
var templateCallBack = exports.templateCallBack = function templateCallBack() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/template_call_back',
    method: 'get'
  });
};
//获取地区
var industrialPark = exports.industrialPark = function industrialPark() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/industrial_park',
    method: 'get'
  });
};

//案件标签
var caseLabel = exports.caseLabel = function caseLabel() {
  return (0, _request.default)({
    url: '/system/dict/data/dictType/case_label',
    method: 'get'
  });
};