"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMediation = addMediation;
exports.delMediation = delMediation;
exports.exportMediation = exportMediation;
exports.getMediation = getMediation;
exports.listMediation = listMediation;
exports.updateMediation = updateMediation;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询调解记录信息列表
function listMediation(query) {
  return (0, _request.default)({
    url: '/copyright/mediation/list',
    method: 'get',
    params: query
  });
}

// 查询调解记录信息详细
function getMediation(id) {
  return (0, _request.default)({
    url: '/copyright/mediation/' + id,
    method: 'get'
  });
}

// 新增调解记录信息
function addMediation(data) {
  return (0, _request.default)({
    url: '/copyright/mediation',
    method: 'post',
    data: data
  });
}

// 修改调解记录信息
function updateMediation(data) {
  return (0, _request.default)({
    url: '/copyright/mediation',
    method: 'put',
    data: data
  });
}

// 删除调解记录信息
function delMediation(id) {
  return (0, _request.default)({
    url: '/copyright/mediation/' + id,
    method: 'delete'
  });
}

// 导出调解记录信息
function exportMediation(query) {
  return (0, _request.default)({
    url: '/copyright/mediation/export',
    method: 'get',
    params: query
  });
}