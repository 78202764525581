"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bandIngWc = bandIngWc;
exports.getAdjusMediat = getAdjusMediat;
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requests = _interopRequireDefault(require("@/utils/requests"));
// 登录方法
function login(username, password, code, uuid) {
  var data = {
    username: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data
  });
}

// 获取用户详细信息
function getInfo() {
  return (0, _request.default)({
    url: '/getInfo',
    method: 'get'
  });
}

// 退出方法
function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'post'
  });
}

// 获取验证码
function getCodeImg() {
  return (0, _request.default)({
    url: '/captchaImage',
    method: 'get'
  });
}

// 绑定微信
function bandIngWc() {
  return (0, _request.default)({
    url: '/weadjust/bandIngWc',
    method: 'post'
  });
}
function getAdjusMediat(data) {
  console.log(data, 'datadtadtadad');
  return (0, _requests.default)({
    url: '/weadjust/getAdjusMediat',
    method: 'post',
    data: data
  });
}