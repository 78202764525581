"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStore = exports.removeStore = exports.getStore = void 0;
/**
 * 存储localStorage
 */
var setStore = exports.setStore = function setStore(name, content) {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
var getStore = exports.getStore = function getStore(name) {
  if (!name) return;
  var value = window.localStorage.getItem(name);
  if (value !== null) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = value;
    }
  }
  return value;
};

/**
 * 删除localStorage
 */
var removeStore = exports.removeStore = function removeStore(name) {
  if (!name) return;
  window.localStorage.removeItem(name);
};