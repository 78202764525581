"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.officialPractice = officialPractice;
exports.platform = platform;
exports.sidebarlayout = sidebarlayout;
exports.usersourceType = usersourceType;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.split");
// AboutView.vue
function platform() {
  var data = window.location.search.split('=')[1];
  var arr = {
    supervType: '',
    phone: '',
    href: 'null',
    rubric: '',
    back: 'back',
    customer: 'customer'
  };
  if (data == "fuzhou" || data == "FZ") {
    arr.supervType = 1;
    arr.phone = "0791-86810110";
    arr.href = "https://work.weixin.qq.com/kfid/kfc05ca5105eb0f67c0?FromMpMsg=1&MpAppId=wxa964e3d7c0ca9253";
    arr.rubric = "乾诚调解委员会";
  } else if (data == "xiangyang" || data == "XY") {
    arr.supervType = 2;
    arr.phone = "0710-3337535";
    arr.href = "https://work.weixin.qq.com/kfid/kfc48fe3f526a67705b";
    arr.rubric = "襄阳襄州网络金融纠纷调解中心";
  } else if (data == "changning" || data == "CN") {
    arr.supervType = 3;
    arr.phone = "0734-7298911";
    arr.href = "https://work.weixin.qq.com/kfid/kfcad50a826dd740645";
    arr.rubric = "常宁市数科纠纷调解中心";
  } else if (data == "jiuding" || data == "JD") {
    arr.supervType = 4;
    arr.phone = "4008916578/0351-5221697";
    arr.href = "https://mp.weixin.qq.com/cgi-bin/home?t=home/index&token=313295679&lang=zh_CN";
    arr.rubric = "山西九鼎金融纠纷调解中心";
    arr.back = "backsx";
    arr.customer = "customersx";
  } else if (data == "duodao" || data == "DD") {
    arr.supervType = 5;
    arr.phone = "4009155317";
    arr.href = "https://work.weixin.qq.com/kfid/kfc4bfa81fe2db8ad0d";
    arr.rubric = "荆门掇刀区商事仲裁调解服务中心";
  } else if (data == "yangxian" || data == "YX") {
    arr.supervType = 6;
    arr.phone = "0916-8216386";
    arr.href = "https://work.weixin.qq.com/kfid/kfcad50a826dd740645";
    arr.rubric = "汉中市洋县纠纷调解中心";
  } else if (data == "正合" || data == "ZH") {
    arr.supervType = 91;
    arr.phone = "4009261971";
    arr.href = "https://work.weixin.qq.com/kfid/kfcbbb7f20c160b5efd";
    arr.rubric = "正合多元调解中心";
    arr.back = "backsx";
    arr.customer = "customersx";
  } else if (data == "忻城" || data == "XC") {
    arr.supervType = 7;
    arr.phone = "";
    arr.href = "https://work.weixin.qq.com/kfid/kfc752263506a9712ac";
    arr.rubric = "忻城县汇川商事纠纷调解中心";
    arr.back = "backsx";
    arr.customer = "customersx";
  } else if (data == "天门" || data == "TM") {
    arr.supervType = 8;
    arr.phone = "02787528299";
    arr.href = "https://work.weixin.qq.com/kfid/kfca150696cd27fbb2b";
    arr.rubric = "天门市人民法院特邀民商事调解中心";
    arr.back = "backsx";
    arr.customer = "customersx";
  } else if (data == "襄阳司法" || data == "XYSF") {
    arr.supervType = 9;
    arr.phone = "0710-3337535";
    arr.href = "https://work.weixin.qq.com/kfid/kfc48fe3f526a67705b";
    arr.rubric = "襄阳襄州司法局特邀网络金融纠纷调解中心";
    arr.back = "backsx";
    arr.customer = "customersx";
  } else if (data == "华容调解" || data == "HR") {
    arr.supervType = 11;
    arr.phone = "400-3337535";
    arr.href = "https://work.weixin.qq.com/kfid/kfcdb897bc4a40b8083";
    arr.rubric = "华容县";
    arr.back = "backsx";
    arr.customer = "customersx";
  }
  return arr;
}
// Practice.vue 调解函
function officialPractice(data) {
  var arr = {
    phone: '',
    organization: '',
    num: ''
  };
  if (data == 1) {
    arr.num = '1';
    arr.phone = "0791-86810110";
    arr.organization = "乾诚调解委员会";
  } else if (data == 2) {
    arr.num = '2';
    // this.phone = "0710-3339890  /  0710-3337535";
    arr.phone = "0710-3337535";
    arr.organization = "襄阳襄州网络金融纠纷调解中心";
  } else if (data == 3) {
    arr.num = '3';
    arr.phone = "0734-7298911";
    arr.organization = "常宁市数科纠纷调解中心";
  } else if (data == 4) {
    arr.num = '4';
    arr.phone = "4008-916578";
    arr.organization = "山西九鼎金融纠纷调解中心";
  } else if (data == 5) {
    arr.num = '5';
    arr.phone = "4009155317";
    arr.organization = "荆门掇刀区商事仲裁调解服务中心";
  } else if (data == 6) {
    arr.num = '6';
    arr.phone = "0916-8216386";
    arr.organization = "汉中市洋县桓天纠纷调解中心";
  } else if (data == 7) {
    arr.num = '7';
    arr.phone = "07726010090";
    arr.organization = "忻城县汇川商事纠纷调解中心";
  } else if (data == 8) {
    arr.num = '8';
    arr.phone = "02787528299";
    arr.organization = "武汉市武昌区墨守民商事调解中心";
  } else if (data == 9) {
    arr.num = '9';
    arr.phone = "0710-3337535";
    arr.organization = "襄阳襄州网络金融纠纷调解中心";
  } else if (data == 11) {
    arr.num = '11';
    arr.phone = "400-3337535";
    arr.organization = "华容县民商事调解中心";
  } else if (data == 91) {
    arr.num = '91';
    arr.phone = "4009261971";
    arr.organization = "正合多元调解中心";
  }
  return arr;
}
// /layout/index.vue 水印
function sidebarlayout(type) {
  var arr = {
    sidebarContainers: "",
    platform: ''
  };
  if (type == "1") {
    arr.sidebarContainers = "sidebar-container sidebarContainers";
    arr.platform = "抚州市临川区乾诚人民调解委员会";
  } else if (type == "2") {
    arr.sidebarContainers = "sidebar-container sidebarContainers1";
    arr.platform = "襄阳襄州网络金融纠纷调解中心";
  } else if (type == "3") {
    arr.sidebarContainers = "sidebar-container sidebarContainers2";
    arr.platform = "常宁市数科纠纷调解中心";
  } else if (type == "4") {
    arr.sidebarContainers = "sidebar-container sidebarContainers3";
    arr.platform = "山西九鼎金融纠纷调解中心";
  } else if (type == "5") {
    arr.sidebarContainers = "sidebar-container sidebarContainers5";
    arr.platform = "荆门掇刀区商事仲裁调解服务中心";
  } else if (type == "6") {
    arr.sidebarContainers = "sidebar-container sidebarContainers6";
    arr.platform = "汉中市洋县纠纷调解中心";
  } else if (type == "7") {
    arr.sidebarContainers = "sidebar-container sidebarContainers7";
    arr.platform = "忻城县汇川商事纠纷调解中心";
  } else if (type == "8") {
    arr.sidebarContainers = "sidebar-container sidebarContainers8";
    arr.platform = "天门市人民法院特邀民商事调解中心";
  } else if (type == "9") {
    arr.sidebarContainers = "sidebar-container sidebarContainers9";
    arr.platform = "襄阳襄州司法局特邀网络金融纠纷调解中心";
  } else if (type == "11") {
    arr.sidebarContainers = "sidebar-container sidebarContainers8";
    arr.platform = "华容县民商事调解中心";
  }
  return arr;
}

// /views/system/user/profile/index.vue 聘书
function usersourceType(data) {
  var arr = {
    num: "",
    platform: ""
  };
  // 判断调解平台
  if (data == '1') {
    arr.num = '1';
    arr.platform = "抚州市临川区乾诚人民调解委员会";
  } else if (data == '2') {
    arr.num = '2';
    arr.platform = "襄阳襄州网络金融纠纷调解中心";
  } else if (data == '3') {
    arr.num = '3';
    arr.platform = "常宁市数科纠纷调解中心";
  } else if (data == '4') {
    arr.num = '4';
    arr.platform = "山西九鼎金融纠纷调解中心";
  } else if (data == '5') {
    arr.num = '5';
    arr.platform = "荆门掇刀区商事仲裁调解服务中心";
  } else if (data == '6') {
    arr.num = '6';
    arr.platform = "汉中市洋县桓天纠纷调解中心";
  } else if (data == '7') {
    arr.num = '7';
    arr.platform = "忻城县汇川商事纠纷调解中心";
  } else if (data == '8') {
    arr.num = '8';
    arr.platform = "天门市人民法院特邀民商事调解中心";
  } else if (data == '9') {
    arr.num = '9';
    arr.platform = "襄阳襄州司法局特邀网络金融纠纷调解中心";
  } else if (data == '11') {
    arr.num = '11';
    arr.platform = "华容县民商事调解中心";
  }
  return arr;
}