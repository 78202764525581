"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhiyunyi-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
//axios.defaults.baseURL = 'http://192.168.0.4:8080'
_axios.default.defaults.baseURL = 'http://localhost:8096';

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-type'] = 'application/json';
var requests = _axios.default.create({
  baseURL: '/api',
  //直接指定
  timeout: 3000
  // baseURL:import.meta.env.VITE_BASE_URL  //间接动态指定
});
var _default = exports.default = requests;